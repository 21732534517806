import getChannel, { IKIPOS } from "./channel"

const posTheme = {
    loginTitle: "IKIPOS",
    backgroundColor: "#3D6CB2",
    modalNoti:"#3D6CB2",
    buttonYes:"#F7C23E",
    logo: "/images/logo/ikipos_logo.png",
    logoTab: "/images/logo/ikipos_logo_tab.png",
    logoLogin: "/images/logo/ikipos_login.jpg",
    favicon: "/images/logo/ikipos_logo_tab.png"

}

const ikitechTheme = {
    loginTitle: "BECARE",
    backgroundColor: "#3D6CB2",
    modalNoti:"#E56F25",
    buttonYes:"#F7C23E",
    logo: "/images/logo/ikitech_logo.jpg",
    logoTab: "/images/logo/ikitech_logo_tab.png",
    logoLogin: "/images/logo/ikitech_login.jpg",
    favicon: "/images/logo/becare_favicon.png"
}


export default function themeData() {
    if (getChannel() == IKIPOS) {
        return posTheme
    }

    return ikitechTheme
}