import * as Types from "../constants/ActionType";
import * as paymentApi from "../data/remote/payment";
import history from "../history";

export const fetchAllPayment = (store_code) => {
  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi.fetchAllPayment(store_code).then((res) => {
      console.log(res)
      dispatch({
        type: Types.SHOW_LOADING,
        loading: "hide"
      })
      if(res.data.code !== 401)
      dispatch({
        type: Types.FETCH_ALL_PAYMENT,
        data: res.data.data,
      });
    });
  };
};

export const createPayment = (store_code,data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi
      .createPayment(store_code,data)
      .then((res) => {
        paymentApi.fetchAllPayment(store_code).then((res) => {
          dispatch({
            type: Types.SHOW_LOADING,
            loading: "hide"
          })
          if(res.data.code !== 401)

          dispatch({
            type: Types.FETCH_ALL_PAYMENT,
            data: res.data.data,
          });
          dispatch({
            type: Types.ALERT_UID_STATUS,
            alert: {
              type: "success",
              title: "Thành công ",
              disable: "show",
              content: res.data.msg,
            },
          });
        });
      })
      .catch(function (error) {

        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "danger",
            title: "Lỗi",
            disable: "show",
            content: error?.response?.data?.msg,
          },
        });
      })
      .catch(function (error) {
        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "danger",
            title: "Lỗi",
            disable: "show",
            content: error?.response?.data?.msg,
          },
        });
      });
  };
};

export const updatePayment = (store_code,paymentId,data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi

      .updatePayment(store_code,paymentId,data)
      .then((res) => {
        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({

          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "success",
            title: "Thành công ",
            disable: "show",
            content: res.data.msg,
          },
        });
        history.goBack();
      })
      .catch(function (error) {
        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "danger",
            title: "Lỗi",
            disable: "show",
            content: error?.response?.data?.msg,
          },
        });
      });
  };
};





export const destroyPayment = (store_code , id) => {

  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi
      .destroyPayment(store_code, id)
      .then((res) => {
        paymentApi
          .fetchAllPayment(store_code)
          .then((res) => {
            dispatch({
              type: Types.SHOW_LOADING,
              loading: "hide"
            })
            if(res.data.code !== 401)

            dispatch({
              type: Types.FETCH_ALL_PAYMENT,
              data: res.data.data,
            });
            dispatch({
              type: Types.ALERT_UID_STATUS,
              alert: {
                type: "success",
                title: "Thành công ",
                disable: "show",
                content: res.data.msg,
              },
            });
          })
          .catch(function (error) {
            dispatch({
              type: Types.SHOW_LOADING,
              loading: "hide"
            })
            dispatch({
              type: Types.ALERT_UID_STATUS,
              alert: {
                type: "danger",
                title: "Lỗi",
                disable: "show",
                content: error?.response?.data?.msg,
              },
            });
          });
      })
      .catch(function (error) {
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "danger",
            title: "Lỗi",
            disable: "show",
            content: error?.response?.data?.msg,
          },
        });
      });
  };
};


export const fetchPaymentId = (store_code, id) => {
  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi.fetchPaymentId(store_code, id).then((res) => {
      dispatch({
        type: Types.SHOW_LOADING,
        loading: "hide"
      })
      if(res.data.code !== 401)
      dispatch({
        type: Types.FETCH_ID_PAYMENT,
        data: res.data.data,
      });
    });
  };
};


export const updatePaymentMethod = (store_code,paymentId,data) => {
  return (dispatch) => {
    dispatch({
      type: Types.SHOW_LOADING,
      loading: "show"
    })
    paymentApi

      .updatePayment(store_code,paymentId,data)
      .then((res) => {
        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "success",
            title: "Thành công ",
            disable: "show",
            content: res.data.msg,
          },
        });
        paymentApi.fetchAllPayment(store_code).then((res) => {
    
          if(res.data.code !== 401)
          dispatch({
            type: Types.FETCH_ALL_PAYMENT,
            data: res.data.data,
          });
        });
      })
      .catch(function (error) {
        dispatch({
          type: Types.SHOW_LOADING,
          loading: "hide"
        })
        dispatch({
          type: Types.ALERT_UID_STATUS,
          alert: {
            type: "danger",
            title: "Lỗi",
            disable: "show",
            content: error?.response?.data?.msg,
          },
        });
      });
  };
};