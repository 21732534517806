import { combineReducers } from 'redux';
import {vip_user} from './vip_user'




export const vipUserReducers = combineReducers({
    vip_user,
});

