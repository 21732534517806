import React, { Component } from "react";
import * as agencyAction from "../../../actions/agency"
import { connect } from "react-redux";
import themeData from "../../../ultis/theme_data";
import { formatNumber, formatNoD } from "../../../ultis/helpers";


class ModalCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txtName: "",
      commission_percent: "",
      plan: ""
    };
  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    if (name == "commission_percent") {
      const _value = formatNumber(value);
      if (!isNaN(Number(_value))) {
        if (_value < 101) {
          if (_value == "") {
            this.setState({ [name]: "" });
          } else {
            this.setState({ [name]: _value });
          }
        } else {
          this.setState({ [name]: "100" });
        }
      }
    }
    else {
      this.setState({ [name]: value });
    }


  };
  onSave = (e) => {
    e.preventDefault();
    window.$('.modal').modal('hide');
    var { txtName, plan, commission_percent } = this.state
    this.props.createAgencyType(this.props.store_code,
      {
        name: txtName,
        commission_percent: commission_percent,
        plan: plan
      });
    this.setState({
      txtName: "",
      commission_percent: "",
      plan: ""
    })
  };
  render() {
    var { txtName, commission_percent, plan } = this.state;
    return (
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        id="createType"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: themeData().backgroundColor }}>
              <h4 style={{ color: "white" }}>Thêm cấp</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>

            </div>
            <form
              onSubmit={this.onSave}
              role="form"
              action="#"
              method="post"
            >
              <div class="modal-body">

                <div class="form-group">
                  <label for="product_name">Tên cấp đại lý</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    placeholder="Nhập..."
                    autocomplete="off"
                    value={txtName}
                    onChange={this.onChange}
                    name="txtName"
                  />
                </div>
                <div class="form-group">
                  <label for="product_name">% Chiết khấu</label>
                  <input
                    required
                    type="number"
                    class="form-control"
                    placeholder="Nhập..."
                    autocomplete="off"
                    value={commission_percent}
                    onChange={this.onChange}
                    name="commission_percent"
                  />
                </div>
                <div class="form-group">
                  <label for="product_name">Gói</label>
                  <input
                    required
                    type="number"
                    class="form-control"
                    placeholder="Nhập..."
                    autocomplete="off"
                    value={plan}
                    onChange={this.onChange}
                    name="plan"
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Đóng
                </button>
                <button type="submit" class="btn btn-warning">
                  Tạo
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch, props) => {
  return {
    createAgencyType: (id, form) => {
      dispatch(agencyAction.createAgencyType(id, form));
    },
  };
};
export default connect(null, mapDispatchToProps)(ModalCreate);
