import React, { Component } from "react";
import { connect } from "react-redux";
import * as agencyAction from "../../../actions/agency";
import CreateModal from "./ModalCreate"
import Table from "./Table";
import { formatNumber, formatNoD } from "../../../ultis/helpers";
import { shallowEqual } from "../../../ultis/shallowEqual";
class ListAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      percent_deduct_funds: ""
    };
  }


  componentDidMount() {

    this.props.fetchAllAgencyType(this.props.store_code);
    this.props.fetchAgencyConf(this.props.store_code);

  }

  componentWillReceiveProps(nextProps) {
    if (
      !shallowEqual(nextProps.config, this.props.config) ||
      this.state.id == null ||
      nextProps.tabId != this.props.tabId
    ) {
      var config = nextProps.config;
      this.setState({
        id: config.id,
        percent_deduct_funds: config.percent_deduct_funds,

      });
    }

  }

  onChange = (e) => {
    var target = e.target;
    var name = target.name;
    var value = target.value;
    const _value = formatNumber(value);
    if (
      name == "percent_deduct_funds"
    ) {
      if (_value < 101) {
        if (target.value == "") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      } else {
        this.setState({ [name]: "100" });
      }
    } else {
      if (!isNaN(Number(_value))) {
        value = formatNoD(_value);
        value = value.toString().replace(/\./g, ",");


        if (target.value == "") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      }
    }
  };

  onSave = (e) => {
    e.preventDefault();
    var {
      percent_deduct_funds
    } = this.state;
    window.$(".modal").modal("hide");
    this.props.updateConfig(this.props.store_code, {
      percent_deduct_funds
    });
  };


  render() {
    var { types, store_code } = this.props
    var { percent_deduct_funds } = this.state
    return (
      <div id="wrapper">
        <CreateModal store_code={store_code} />

        <div className="card-body">
          <a
            style={{ float: "right" }}

            data-toggle="modal" data-target="#createType"
            class={`btn btn-info btn-icon-split btn-sm`}
          >
            <span class="icon text-white-50">
              <i class="fas fa-plus"></i>
            </span>
            <span style={{ color: "white" }} class={`text `}>Thêm cấp</span>
          </a>
          <Table types={types} store_code={store_code} />
          <div className="form-group">

            <label htmlFor="name">Set % trích quỹ thiện nguyện trên mỗi đơn hàng</label>
            <i>
               (Ví dụ: 0.1)
            </i>
            <input
              type="text"
              class="form-control"
              name="percent_deduct_funds"
              onChange={this.onChange}
              value={percent_deduct_funds}
              style={{ maxWidth: "40%" }}
            />
          </div>


          <div className="form-group">
            <button type="button" onClick={this.onSave} class={`btn btn-primary btn-sm `}>
              <i class="fa fa-save"></i> Lưu
            </button>
          </div>

        </div>





      </div>
    );

  }
}

const mapStateToProps = (state) => {
  return {
    config: state.agencyReducers.agency.config,
    types: state.agencyReducers.agency.allAgencyType,
    auth: state.authReducers.login.authentication,

  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAllAgencyType: (store_code) => {
      dispatch(agencyAction.fetchAllAgencyType(store_code));
    },
    fetchAgencyConf: (store_code) => {
      dispatch(agencyAction.fetchAgencyConf(store_code));
    },
    updateConfig: (store_code, data) => {
      dispatch(agencyAction.updateConfig(store_code, data));
    },

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListAgency);
