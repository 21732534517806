/// REGISTER REDUCER
export const LOADING_CREATE_QUESTION =  "LOADING_CREATE_QUESTION";
export const NONE =  "NONE";
export const LOADING = "LOADING";
/// LOGTIN REDUCER
export const AUTHENTICATION_LOGIN = "AUTHENTICATION_LOGIN";
export const RESET_STATUS_LOADING = "RESET_STATUS_LOADING"
/// FORGOT REDUCER
export const SUCCESS = "SUCCESS";
/// REGISTER REDUCER
export const USER_REGISTER = "USER_REGISTER";
export const FAILURE = "FAILURE";
/// LOGTIN REDUCER
export const UPDATE_STATUS_SYNC_SHIPMENT = "UPDATE_STATUS_SYNC_SHIPMENT"
export const USER_PHONE_FORGOT = "USER_PHONE_FORGOT";

//BRANCH
export const CHANGE_BRANCH = "CHANGE_BRANCH";

export const FETCH_PERMISSION = "FETCH_PERMISSION";
export const LOAD_PERMISSION = "LOAD_PERMISSION";

/// STORE REDUCER
export const FETCH_ALL_STORE = "FETCH_ALL_STORE";
export const FETCH_ID_STORE = "FETCH_ID_STORE";
export const FETCH_ALL_TYPESTORE = "FETCH_ALL_TYPESTORE";

export const FETCH_ALL_DECENTRALIZATION = "FETCH_ALL_DECENTRALIZATION";
export const FETCH_ID_DECENTRALIZATION = "FETCH_ID_DECENTRALIZATION";

export const FETCH_ALL_STAFF = "FETCH_ALL_STAFF";
export const FETCH_ID_STAFF = "FETCH_ID_STAFF";

/// STORE_ADDRESS REDUCERS
export const FETCH_ALL_STORE_ADDRESS = "FETCH_ALL_STORE_ADDRESS";
export const FETCH_ID_STORE_ADDRESS = "FETCH_ID_STORE_ADDRESS";
export const FETCH_SHIP_CONFIG = "FETCH_SHIP_CONFIG"
/// CATEGORY PRODUCT_ADDRESS REDUCERS
export const FETCH_ALL_CATEGORY_PRODUCT = "FETCH_ALL_CATEGORY_PRODUCT";
export const FETCH_ID_CATEGORY_PRODUCT = "FETCH_ALL_CATEGORY_PRODUCT";

/// PRODUCT REDUCER
export const FETCH_ALL_PRODUCT = "FETCH_ALL_PRODUCT";
export const FETCH_ID_PRODUCT = "FETCH_ID_PRODUCT";
export const FETCH_ALL_PRODUCT_LIST = "FETCH_ALL_PRODUCT_LIST";
export const FETCH_ALL_PRODUCT_TIKI = "FETCH_ALL_PRODUCT_TIKI";
export const FETCH_ALL_PRODUCT_SENDO = "FETCH_ALL_PRODUCT_SENDO";
export const FETCH_ALL_PRODUCT_SHOPEE = "FETCH_ALL_PRODUCT_SHOPEE";
export const FETCH_ID_PRODUCT_AGENCY_PRICE = "FETCH_ID_PRODUCT_AGENCY_PRICE";

export const UPLOAD_PRODUCT_IMG = "UPLOAD_PRODUCT_IMG";
export const UPLOAD_PRODUCT_VIDEO = "UPLOAD_PRODUCT_VIDEO";
export const UPLOAD_ALL_PRODUCT_IMG = "UPLOAD_ALL_PRODUCT_IMG";
export const UPLOAD_ALL_DISTRIBUTE_IMG = "UPLOAD_ALL_DISTRIBUTE_IMG";

/// ATTRIBUTE REDUCER
export const FETCH_ALL_ATTRIBUTE_PRODUCT = "FETCH_ALL_ATTRIBUTE_PRODUCT";

///DISCOUNT REDUCER
export const FETCH_ALL_DISCOUNT = "FETCH_ALL_DISCOUNT";

export const FETCH_ID_DISCOUNT = "FETCH_ID_DISCOUNT";
export const UPLOAD_DISCOUNT_IMG = "UPLOAD_DISCOUNT_IMG";

///vOUCHER REDUCER
export const FETCH_ALL_VOUCHER = "FETCH_ALL_VOUCHER";

export const FETCH_ID_VOUCHER = "FETCH_ID_VOUCHER";
export const UPLOAD_VOUCHER_IMG = "UPLOAD_VOUCHER_IMG";

///COMBO REDUCER
export const FETCH_ALL_COMBO = "FETCH_ALL_COMBO";

export const FETCH_ID_COMBO = "FETCH_ID_COMBO";

export const FETCH_ALL_BONUS_PRODUCT = "FETCH_ALL_BONUS_PRODUCT";
export const UPLOAD_BONUS_PRODUCT_IMG = "UPLOAD_BONUS_PRODUCT_IMG";
export const FETCH_ID_BONUS_PRODUCT = "FETCH_ID_BONUS_PRODUCT";
export const UPLOAD_COMBO_IMG = "UPLOAD_COMBO_IMG";

//SHIFT REDUCER
export const FETCH_ALL_SHIFT = "FETCH_ALL_SHIFT";

export const FETCH_ID_SHIFT = "FETCH_ID_SHIFT";
export const FETCH_ID_CALENDAR_SHIFT = "FETCH_ID_CALENDAR_SHIFT";
export const FETCH_ALL_CALENDAR_SHIFT = "FETCH_ALL_CALENDAR_SHIFT";
export const FETCH_ALL_TIME_SHEET = "FETCH_ALL_TIME_SHEET";
export const FETCH_ALL_REQUEST_MOBILE = "FETCH_ALL_REQUEST_MOBILE";
export const FETCH_ALL_REQUEST_REMOTE = "FETCH_ALL_REQUEST_REMOTE";
export const FETCH_STAFF_MOBILE_CHECKIN = "FETCH_STAFF_MOBILE_CHECKIN";
///CATEGORY BLOG REDUCER
export const FETCH_ALL_CATEGORY_BLOG = "FETCH_ALL_CATEGORY_BLOG";
export const FETCH_ID_CATEGORY_BLOG = "FETCH_ID_CATEGORY_BLOG";
export const UPLOAD_CATEGORY_BLOG_IMG = "UPLOAD_CATEGORY_BLOG_IMG";

//CONFIG VIP
export const UPLOAD_CONFIG_VIP_IMG_LOGIN = "UPLOAD_CONFIG_VIP_IMG_LOGIN";
export const UPLOAD_CONFIG_VIP_IMG_LOGO = "UPLOAD_CONFIG_VIP_IMG_LOGO";
export const UPLOAD_CONFIG_VIP_IMG_LOGO_AFTER =
  "UPLOAD_CONFIG_VIP_IMG_LOGO_AFTER";

export const FETCH_ALL_BANNER_ADS = "FETCH_ALL_BANNER_ADS";
export const FETCH_ID_BANNER_ADS = "FETCH_ID_BANNER_ADS";
export const UPLOAD_BANNER_ADS_IMG = "UPLOAD_BANNER_ADS_IMG";

///BLOG REDUCER
export const FETCH_ALL_BLOG = "FETCH_ALL_BLOG";
export const FETCH_ID_BLOG = "FETCH_ID_BLOG";
export const UPLOAD_BLOG_IMG = "UPLOAD_BLOG_IMG"
export const FETCH_ALL_TRAIN_COURSE = "FETCH_ALL_TRAIN_COURSE";
export const FETCH_ID_TRAIN_COURSE = "FETCH_ID_TRAIN_COURSE";
export const FETCH_ID_TRAIN_QUIZ = "FETCH_ID_TRAIN_QUIZ"
export const FETCH_ALL_TRAIN_LESSON = "FETCH_ALL_TRAIN_LESSON"
export const FETCH_ID_TRAIN_LESSON = "FETCH_ID_TRAIN_LESSON";
export const FETCH_ALL_TRAIN_QUIZ = "FETCH_ALL_TRAIN_QUIZ"

/// SHIPMENT REDUCERS
export const FETCH_ALL_SHIPMENT = "FETCH_ALL_SHIPMENT";
export const FETCH_ID_SHIPMENT = "FETCH_ID_SHIPMENT";
export const RESET_ALL_SHIPMENT = "RESET_ALL_SHIPMENT";
export const FETCH_ALL_CALCULATE_SHIPMENT = "FETCH_ALL_CALCULATE_SHIPMENT"
/// PAYMENT REDUCERS
export const FETCH_ALL_PAYMENT = "FETCH_ALL_PAYMENT";
export const FETCH_ID_PAYMENT = "FETCH_ID_PAYMENT";

/// CUSTOMER REDUCERS

export const FETCH_ALL_CUSTOMER = "FETCH_ALL_CUSTOMER";
export const FETCH_ID_CUSTOMER = "FETCH_ID_CUSTOMER";
export const CREATED_CUSTOMER = "CREATED_CUSTOMER";
export const FETCH_ALL_POINT_HISTORY = "FETCH_ALL_POINT_HISTORY";


/// CUSTOMER SALES REDUCERS
export const FETCH_ALL_CUSTOMER_SALE = "FETCH_ALL_CUSTOMER_SALE";
export const FETCH_ID_CUSTOMER_SALE = "FETCH_ID_CUSTOMER_SALE";
export const CREATED_CUSTOMER_SALE = "CREATED_CUSTOMER_SALE";

/// REVENUE_EXPENDITURES REDUCERS

export const FETCH_ALL_REVENUE_EXPENDITURES = "FETCH_ALL_REVENUE_EXPENDITURES";
export const FETCH_REVENUE_EXPENDITURES_BY_ID =
  "FETCH_REVENUE_EXPENDITURES_BY_ID";
//USER REDUCERS
export const FETCH_ID_USER = "FETCH_ID_USER";
export const UPLOAD_USER_IMG = "UPLOAD_USER_IMG";
export const UPLOAD_STORE_IMG = "UPLOAD_STORE_IMG";

// CONFIG VIP
export const FETCH_CONFIG_VIP_USER = "FETCH_CONFIG_VIP_USER";

/// BILL REDUCERS
export const FETCH_ALL_BILL = "FETCH_ALL_BILL";
export const FETCH_ID_BILL = "FETCH_ID_BILL";
export const FETCH_BILL_HISTORY = "FETCH_BILL_HISTORY";

/// HISTORY PAY
export const FETCH_ALL_HISTORY_PAY = "FETCH_ALL_HISTORY_PAY";
export const FETCH_ID_HISTORY_PAY = "FETCH_ID_BILL";
export const GET_CALCULATE = "GET_CALCULATE"
//DELIVERY
export const FETCH_DELIVERY_HISTORY = "FETCH_DELIVERY_HISTORY";

export const FETCH_ID_CHAT = "FETCH_ID_CHAT";
export const FETCH_ID_CHAT_USER = "FETCH_ID_CHAT_USER";
export const FETCH_ALL_CHAT = "FETCH_ALL_CHAT";
export const ALERT_UID_STATUS = "ALERT_UID_STATUS";

export const SHOW_LOADING = "SHOW_LOADING";
export const FETCH_ALL_BADGE = "FETCH_ALL_BADGE";

/// AGENCY

export const FETCH_ALL_AGENCY_CONFIG = "FETCH_ALL_AGENCY_CONFIG";
export const FETCH_ALL_AGENCY_STEP = "FETCH_ALL_AGENCY_STEP";
export const FETCH_ALL_AGENCY_HISTORY_PAYMENT =
  "FETCH_ALL_AGENCY_HISTORY_PAYMENT";
export const FETCH_ALL_AGENCY_TYPE = "FETCH_ALL_AGENCY_TYPE";
export const FETCH_ALL_AGENCY_TOP_REPORT = "FETCH_ALL_AGENCY_TOP_REPORT";

export const FETCH_ALL_AGENCY = "FETCH_ALL_AGENCY";
export const FETCH_ALL_AGENCY_REQUEST_PAYMENT =
  "FETCH_ALL_AGENCY_REQUEST_PAYMENT";

export const GET_BONUS_AGENCY_CONFIG = "GET_BONUS_AGENCY_CONFIG";
export const UPDATE_BONUS_AGENCY_CONFIG = "UPDATE_BONUS_AGENCY_CONFIG";
export const ADD_BONUS_STEP_AGENCY = "ADD_BONUS_STEP_AGENCY";
export const UPDATE_BONUS_STEP_AGENCY = "UPDATE_BONUS_STEP_AGENCY";
export const DELETE_BONUS_STEP_AGENCY = "DELETE_BONUS_STEP_AGENCY";
/////

export const FETCH_ALL_REVIEW = "FETCH_ALL_REVIEW";

export const SHOW_LOADING_LAZY = "SHOW_LOADING_LAZY";
export const SHOW_LOADING_SHIPPER = "SHOW_LOADING_SHIPPER";
export const FETCH_ALL_COLLABORATOR_CONFIG = "FETCH_ALL_COLLABORATOR_CONFIG";
export const FETCH_ALL_COLLABORATOR_STEP = "FETCH_ALL_COLLABORATOR_STEP";

export const FETCH_ALL_COLLABORATOR = "FETCH_ALL_COLLABORATOR";
export const FETCH_ALL_COLLABORATOR_REQUEST_PAYMENT =
  "FETCH_ALL_COLLABORATOR_REQUEST_PAYMENT";
export const FETCH_ALL_COLLABORATOR_TOP_REPORT = "FETCH_ALL_COLLABORATOR_TOP_REPORT"
export const FETCH_REWARD_POINT = "FETCH_REWARD_POINT";
export const FETCH_ALL_AGENCY_RECHARGE = "FETCH_ALL_AGENCY_RECHARGE";
export const FETCH_ALL_AGENCY_WITH_DRAW  = "FETCH_ALL_AGENCY_WITH_DRAW";
///CATEGORY BLOG REDUCER
export const FETCH_ALL_SCHEDULE = "FETCH_ALL_SCHEDULE";
export const FETCH_ID_SCHEDULE = "FETCH_ID_SCHEDULE";

export const FETCH_ALL_POPUP = "FETCH_ALL_POPUP";
export const FETCH_ID_POPUP = "FETCH_ID_POPUP";
export const UPLOAD_POPUP_IMG = "UPLOAD_POPUP_IMG";

export const FETCH_TOPTEN_REPORT = "FETCH_TOPTEN_REPORT";
export const FETCH_OVERVIEW_REPORT = "FETCH_OVERVIEW_REPORT";

export const FETCH_PLACE_DISTRICT = "FETCH_PLACE_DISTRICT";
export const FETCH_PLACE_PROVICE = "FETCH_PLACE_PROVICE";
export const FETCH_PLACE_WARDS = "FETCH_PLACE_WARDS";

export const FETCH_THEME = "FETCH_THEME";

export const UPLOAD_THEME_IMG_FACE = "UPLOAD_THEME_IMG_FACE";
export const UPLOAD_THEME_IMG_FAVICON = "UPLOAD_THEME_IMG_FAVICON";
export const UPLOAD_THEME_IMG_LOGO = "UPLOAD_THEME_IMG_LOGO";
export const FETCH_ALL_NOTIFICATION = "FETCH_ALL_NOTIFICATION";

export const ERROR_RESPONSE = "ERROR_RESPONSE";

export const FETCH_ALL_COLLABORATOR_HISTORY_PAYMENT =
  "FETCH_ALL_COLLABORATOR_HISTORY_PAYMENT";

export const POST_ID_HELP = "POST_ID_HELP";
export const POST_ID_CONTACT = "POST_ID_CONTACT";
export const POST_ID_ABOUT = "POST_ID_ABOUT";     
export const POST_ID_TERM = "POST_ID_TERM";
export const POST_ID_RETURN_POLICY = "POST_ID_RETURN_POLICY";
export const POST_ID_SUPPORT_POLICY = "POST_ID_SUPPORT_POLICY";
export const POST_ID_PRIVACY_POLICY = "POST_ID_PRIVACY_POLICY";

// ADD ORDER PROUCT

export const FETCH_ALL_ORDER_PRODUCT = "FETCH_ALL_ORDER_PRODUCT";
export const FETCH_ID_ORDER_PRODUCT = "FETCH_ID_ORDER_PRODUCT";
export const FETCH_ALL_VOUCHER_PRODUCT = "FETCH_ALL_VOUCHER_PRODUCT";
export const FETCH_ALL_PERTION_INFO = "FETCH_ALL_PERTION_INFO";
export const FETCH_SEARCH_PERSION = "FETCH_SEARCH_PERSION";
export const FETCH_INFO_PERSION = "FETCH_INFO_PERSION";
export const FETCH_ALL_COMBO_PRODUCT = "FETCH_ALL_COMBO_PRODUCT";
export const ALERT_UIDS_STATUS = "ALERT_UIDS_STATUS";

// ADD BRANCH STORE

export const FETCH_BRANCH_STORE = "FETCH_BRANCH_STORE";
export const DELETE_BRANCH_STORE = "DELETE_BRANCH_STORE";
export const FETCH_BRANCH_STORE_LOADING = "FETCH_BRANCH_STORE_LOADING";
export const FETCH_BRANCH_STORE_NONE = "FETCH_BRANCH_STORE_NONE";

// ADD SUPPLIER

export const FETCH_ALL_SUPPLIER = "FETCH_ALL_SUPPLIER";
export const FETCH_ID_SUPPLIER = "FETCH_ID_SUPPLIER"

// ADD Inventory

export const FETCH_ALL_SHEETS_INVENTORY = "FETCH_ALL_SHEETS_INVENTORY";
export const FETCH_ALL_ITEM_INVENTORY = "FETCH_ALL_ITEM_INVENTORY";
export const FETCH_ALL_HISTORY_INVENTORY = "FETCH_ALL_HISTORY_INVENTORY";

// ADD IMPORT STOCK

export const FETCH_ALL_IMPORT_STOCK = "FETCH_ALL_IMPORT_STOCK";
export const FETCH_DETAIL_IMPORT_STOCK = "FETCH_DETAIL_IMPORT_STOCK";

export const FETCH_ALL_TRANSFER_STOCK = "FETCH_ALL_TRANSFER_STOCK"
export const FETCH_DETAIL_TRANSFER_STOCK = "FETCH_DETAIL_TRANSFER_STOCK";
export const FETCH_ALL_TRANSFER_STOCK_RECEIVER = "FETCH_ALL_TRANSFER_STOCK_RECEIVER";
// ADD REPORT

export const FETCH_ALL_REPORT_INVENTORY = "FETCH_ALL_REPORT_INVENTORY";
export const FETCH_IMPORT_EXPORT_STOCK = "FETCH_IMPORT_EXPORT_STOCK";
export const FETCH_ALL_INVENTORY_HISTORY = "FETCH_ALL_INVENTORY_HISTORY";
export const FETCH_REPORT_PROFIT = "FETCH_REPORT_PROFIT";
export const FETCH_REPORT_PROFIT_COMPARE = "FETCH_REPORT_PROFIT_COMPARE";
export const FETCH_ALL_REPORT_CUSTOMER_DEBT = "FETCH_ALL_REPORT_CUSTOMER_DEBT";
export const FETCH_ALL_REPORT_SUPPLIER_DEBT = "FETCH_ALL_REPORT_SUPPLIER_DEBT";

export const FETCH_REPORT_EXPENDITURE = "FETCH_REPORT_EXPENDITURE";

// ADD POST ORDER
export const FETCH_LIST_POS_ORDER = "FETCH_LIST_POS_ORDER";
export const FETCH_LIST_POS_ORDER_LOADING = "FETCH_LIST_POS_ORDER_LOADING";

export const POS_ORDER_PAYMENT_LOADING = "POS_ORDER_PAYMENT_LOADING";
export const POS_ORDER_PAYMENT_SUCCESS = "POS_ORDER_PAYMENT_SUCCESS";
export const POS_ORDER_PAYMENT_FAILD = "POS_ORDER_PAYMENT_FAILD";
export const CHANGE_STATUS_ALLOW_PRINT = "CHANGE_STATUS_ALLOW_PRINT";

export const LOADING_CHANGE_QUANTITY_LINE_ITEM = "LOADING_CHANGE_QUANTITY_LINE_ITEM";
export const NONE_CHANGE_QUANTITY_LINE_ITEM = "NONE_CHANGE_QUANTITY_LINE_ITEM";

export const CREATE_POS_ORDER_ITEM = "CREATE_POS_ORDER_ITEM";
export const FETCH_LIST_CART_ITEM = "FETCH_LIST_CART_ITEM";

export const FETCH_ALL_GENERAL_SETTING = "FETCH_ALL_GENERAL_SETTING"

export const FETCH_INFO_CUSTOMER = "FETCH_INFO_CUSTOMER"
