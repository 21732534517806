import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import themeData from "../../../../../ultis/theme_data";
import { format, formatVND } from '../../../../../ultis/helpers';

class ModalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail  : {}
    };
  }

  componentWillReceiveProps(nextProps)
  {
    
    if(this.props.idModalDetail != nextProps.idModalDetail)
    {
      var {idModalDetail , recharge} = nextProps
      var detail = recharge.filter((v,i)=>(
            v.id == idModalDetail
      ))?.[0] || {}
        this.setState({detail})
    }
  }

  render() {
    var {detail} = this.state
    return (
     <>
     {typeof detail.id != "undefined"
     &&  <div
     class="modal fade"
     tabindex="-1"
     role="dialog"
     id="modalDetail"
     data-keyboard="false"
     data-backdrop="static"
   >
     <div class="modal-dialog" role="document">
       <div class="modal-content">
         <div
           class="modal-header"
           style={{ backgroundColor: themeData().backgroundColor }}
         >
           <h4 class="modal-title">{detail?.code}</h4>

           <button
             type="button"
             class="close"
             data-dismiss="modal"
             aria-hidden="true"
           >
             &times;
           </button>
         </div>
         <form
           role="form"
           action="#"
           method="post"
           id="createForm"
         >
           <div class="modal-body">
             <div class="form-group">
               <label>Tên khách hàng</label>

               <input
                 type="text"
                 class="inputModal"
                 disabled
                 value={detail.agency?.customer?.name}
               ></input>
             </div>
             <div class="form-group">
               <label>Số điện thoại</label>

               <input
                 type="text"
                 class="inputModal"
                 disabled
                 value={detail.agency?.customer?.phone_number}

               ></input>
             </div>
             <div class="form-group">
               <label>Thời gian giao dịch</label>

               <input
                 type="text"
                 class="inputModal"
                 disabled
                 value={moment(detail.created_at).format("DD-MM-YYYY HH:mm:ss")}
               ></input>
             </div>
             <div class="form-group">
               <label>Số tiền</label>

               <input
                 type="text"
                 class="inputModal"
                 disabled
                 value={format(detail.money)}
               ></input>
             </div>
           </div>
           <div class="modal-footer">
             <button
               type="button"
               class="btn btn-default"
               data-dismiss="modal"
             >
               Đóng
             </button>
             {/* <button type="submit" class="btn btn-info">
                     Tạo
                   </button> */}
           </div>
         </form>
       </div>
     </div>
   </div>
      }
     </>
    );
  }
}

export default ModalDetail;
