import React, { Component } from "react";
import { connect } from "react-redux";
import * as agencyAction from "../../../actions/agency";
import Table from "./Table";
import TableDraw from "./TableDraw";
import SDateRangePicker from "../../../components/DatePicker/DateRangePicker";
import * as helper from "../../../ultis/helpers";

class ListAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
      date_from : "",
      date_to : ""
    };
  }

  componentDidMount() {
    var date = helper.getDateForChartMonth();
    var params = `?params=true&date_from=${date.from}&date_to=${date.to}`;
    this.props.fetchAllAgencyRecharge(this.props.store_code  , params);

    this.setState({
      date_from : date.from ,
      date_to : date.to
    })
  }
  onChangeType = (e) => {
    var { value } = e.target;
    var {
      date_from ,
      date_to
    } = this.state
    var params = `?params=true&date_from=${date_from}&date_to=${date_to}`;
    if (value == 0) this.props.fetchAllAgencyRecharge(this.props.store_code , params);
    else this.props.fetchAllAgencyWithDraw(this.props.store_code , params);

    this.setState({ type: value });
  };

  onChangeDateFromComponent = (date) => {
    // var from = "";
    // var to = "";
    // try {
    //   from = moment(e.value[0], "DD-MM-YYYY").format("YYYY-MM-DD")
    //   to = moment(e.value[1], "DD-MM-YYYY").format("YYYY-MM-DD")
    // } catch (error) {
    //     from = null
    //     to = null
    // }
    var {type} = this.state

    var params = `?params=true&date_from=${date.from}&date_to=${date.to}`;
    if(type==0)
    this.props.fetchAllAgencyRecharge(this.props.store_code, params);
    else
    this.props.fetchAllAgencyWithDraw(this.props.store_code, params);

    this.setState({
      date_from : date.from ,
      date_to : date.to
    })
  };

  exportPaidHistory = () =>{
    var {
      date_from ,
      date_to
    } = this.state
    var params = `?params=true&date_from=${date_from}&date_to=${date_to}`;
    var {type} = this.state

    if(type==0)
    this.props.exportPaidHistoryRecharge(this.props.store_code, params);
    else
    this.props.exportPaidHistoryWithDraw(this.props.store_code, params);


  }
  render() {
    var { recharge, store_code, with_draw } = this.props;
    var {type , date_from ,date_to} = this.state

    var params = `?params=true&date_from=${date_from}&date_to=${date_to}`;
    return (
      <div id="wrapper">
 

        <div className="" style={{width : "100%"}}>
        <div style={{  display: "flex" , justifyContent : "space-between" }}>
        <div style={{  display: "flex" }}>
          <span
            style={{
              "min-width": "100px",
              fontWeight: 600,

              margin: "auto 0px",
            }}
          >
            Trạng thái
          </span>
          <select
            onChange={this.onChangeType}
            name="report_type"
            value={type}
            style = {{maxWidth : "200px" , margin : "auto"}}
            className="form-control"
          >
            <option value={0}>Nạp tiền</option>
            <option value={1}>Rút tiền</option>
          </select>

          <div style={{ marginLeft: "15px", display: "flex" }}>
                <span
                  style={{
                    "min-width": "80px",
                    "fontWeight" : 600,
                    margin: "auto",
                  }}
                >
                  Thời gian:{" "}
                </span>
                  <div style={{minWidth : "150px"}}>
                  <SDateRangePicker  onChangeDate={this.onChangeDateFromComponent} />

                  </div>
              </div> 
        </div>
        <button
            style={{ margin: "auto 0px" }}
            onClick={this.exportPaidHistory}
            class={`btn btn-danger btn-icon-split btn-sm `}
          >
            <span class="icon text-white-50">
              <i class="fas fa-file-export"></i>
            </span>
            <span style={{ color: "white" }} class="text">
              Export Excel
            </span>
          </button>
        </div>
        <br></br>
          {parseInt(type) === 0 ? (
            <Table params = {params} recharge={recharge} store_code={store_code} />
          ) : (
            <TableDraw params = {params} date_to = {date_to} recharge={with_draw} store_code={store_code} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recharge: state.agencyReducers.agency.recharge,
    with_draw: state.agencyReducers.agency.with_draw,

    auth: state.authReducers.login.authentication,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAllAgencyRecharge: (store_code , params) => {
      dispatch(agencyAction.fetchAllAgencyRecharge(store_code , params));
    },
    fetchAllAgencyWithDraw: (store_code,params) => {
      dispatch(agencyAction.fetchAllAgencyWithDraw(store_code,params));
    },
    exportPaidHistoryRecharge: (store_code, params) => {
      dispatch(agencyAction.exportPaidHistoryRecharge(store_code, params));
    },
    exportPaidHistoryWithDraw: (store_code, params) => {
      dispatch(agencyAction.exportPaidHistoryWithDraw(store_code, params));
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListAgency);
