import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as agencyAction from "../../../../../actions/agency";
import { format, formatNoD } from "../../../../../ultis/helpers";
import moment from "moment";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        name: "",
        id: "",
      },
      modalUpdate: {
        name: "",
        id: "",
      },
    };
    this.optionStatus = [
      { name: "Chờ duyệt", value: 0, color: "blue" },
      { name: "Đã hủy", value: 1, color: "red" },
      { name: "Đã duyệt", value: 2, color: "green" },
    ];
  }

  updateAgencyRecharge = (value, id, name) => {
    this.props.handleModalUpdateRecharge({
      status: value,
      id: id,
      name: this.optionStatus.filter((v) => {
        return v.value == value;
      })[0]?.name,
    });
  };

  render() {
    var { v } = this.props;
    var color = this.optionStatus.filter((_v) => {
      return _v.value == v.status;
    })[0]?.color;
    return (
      <tr
       
        className=""
      >
        <td
          data-toggle="modal"
          data-target="#modalDetailWithDraw"
          onClick={() =>
            this.props.handleShowDetail( v.id)
          }
          className="order-id"
          style={{ minWidth: "160px", color: "blue", cursor: "pointer" }}
        >
          {v.code}
        </td>
        <td className="date">{moment(v.created_at).format("YYYY-MM-DD")}</td>
        <td className="n-product">{v.bank_account_name}</td>
        <td className="total"> {format(v.money)}</td>

        {/* <td
          className="status"
          style={{
            color:
              v.status == 0 ? "blue" : v.status == 1 ? "red" : "green",
          }}
        >
          {v.status == 0
            ? "Chờ duyệt"
            : v.status == 1
            ? "Hủy"
            : "Đã duyệt"}
          <br />
        </td> */}
<td
          className={`group-btn-table three-btn-group`}
          style={{ maxWidth: "150px" }}
        >
          {v.status !== 1 && v.status !== 2 ? (
            <button
              onClick={(e) => {
                this.updateAgencyRecharge(1, v.id);
              }}
              class={`btn btn-danger btn-sm `}
            >
              <i class="fa fa-trash"></i> Hủy
            </button>
          ) : (
            <span
              style={{
                textAlign: "center",
                color: v.status == 0 ? "blue" : v.status == 1 ? "red" : "green",
              }}
            >
              {" "}
              {v.status == 0
                ? "Chờ duyệt"
                : v.status == 1
                ? "Đã hủy"
                : "Đã duyệt"}
            </span>
          )}
          {v.status !== 2 && v.status !== 1 && (
            <button
              onClick={(e) => {
                this.updateAgencyRecharge(2, v.id);
              }}
              data-toggle="modal"
              name="toggle"
              data-target="#isEndModal"
              class={`btn btn-primary btn-sm  `}
            >
              <i class="fa fa-clock-o"></i> Duyệt
            </button>
          )}
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateAgency: (store_code, id, data) => {
      dispatch(agencyAction.updateAgency(store_code, id, data));
    },
  };
};
export default connect(null, mapDispatchToProps)(Table);
