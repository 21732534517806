import React, { Component } from "react";
import * as agencyAction from "../../../../actions/agency"
import { shallowEqual } from "../../../../ultis/shallowEqual";
import { connect } from "react-redux";
import themeData from "../../../../ultis/theme_data";

import { formatNumber, formatNoD } from "../../../../ultis/helpers";

class ModalUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };
  }

  onSave = (e) =>{
    e.preventDefault()
    var {store_code , modal, params} = this.props;
    this.props.updateAgencyWithDraw(store_code , modal.id , {status:modal.status} , params)
    window.$(".modal").modal("hide");
  }
  

  render() {
    var { modal } = this.props;

    console.log(modal);
    return (
      <div
        class="modal fade"
        tabindex="-1"
        role="dialog"
        id="updateWithDraw"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
          <div class="modal-header" style={{ backgroundColor: themeData().backgroundColor }}>
              <h4 style={{ color: "white" }}>Thông báo</h4>

              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>

            </div>
            <form
              onSubmit={this.onSave}
              role="form"
              action="#"
              method="post"
            >
              <div class="modal-body">
      
                <div class="form-group">
                  <label for="product_name">Chuyển trạng thái sang {modal.name}</label>
           
                </div>
              
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-default"
                  data-dismiss="modal"
                >
                  Đóng
                </button>
                <button type="submit" class="btn btn-warning">
                  Xác nhận
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}



const mapDispatchToProps = (dispatch, props) => {
  return {
    updateAgencyWithDraw: (store_code , id, form , params) => {
      dispatch(agencyAction.updateAgencyWithDraw(store_code , id, form , params));
    },
  };
};
export default connect(null, mapDispatchToProps)(ModalUpdate);
