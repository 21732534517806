export const headerImg = [
  {
    index: 1,
    header: "https://i.imgur.com/VNNLEEM.png",
  },
  {
    index: 2,
    header: "https://i.imgur.com/5hykNfv.png",
  },
  {
    index: 3,
    header: "https://i.imgur.com/Iau7FFv.png",
  },
  {
    index: 4,
    header: "https://i.imgur.com/RxlSZq5.png",
  },
  {
    index: 5,
    header: "https://i.imgur.com/bkA4MSp.png",
  },
  {
    index: 6,
    header: "https://i.imgur.com/hL1npwI.png",
  },
  {
    index: 7,
    header: "https://i.imgur.com/AKgtOj5.png",
  },
  {
    index: 8,
    header: "https://i.imgur.com/xu5BZ92.png",
  },
  {
    index: 9,
    header: "https://i.imgur.com/KAMl9hQ.png",
  },
  {
    index: 10,
    header: "https://i.imgur.com/FHQihOD.png",
  },
];
export const bannerImg = [
  {
    index: 1,
    banner: "https://i.imgur.com/FHLz6wR.png",
    width : "1024",
    height : "460"
  },
  {
    index: 2,
    banner: "https://i.imgur.com/wmt3VYB.png",
      width : "1024",
    height : "482"
  },
  {
    index: 3,
    banner: "https://i.imgur.com/IEUr1Gq.png",
    width : "1024",
    height : "576"
  },
  {
    index: 4,
    banner: "https://i.imgur.com/eIOA3Ef.png",
    width : "1024",
    height : "403"
  },
  {
    index: 5,
    banner: "https://i.imgur.com/Z7dC8RG.png",
    width : "1024",
    height : "340"
  },
  {
    index: 6,
    banner: "https://i.imgur.com/xHcw53r.png",
    width : "1024",
    height : "403"
  },
  {
    index: 7,
    banner: "https://i.imgur.com/WFGLPGE.png",
    width : "1024",
    height : "612"
  },
  {
    index: 8,
    banner: "https://i.imgur.com/TVeGGTP.png",
    width : "1024",
    height : "648"
  },
  {
    index: 9,
    banner: "https://i.imgur.com/XExr64n.png",
    width : "1024",
    height : "648"
  },

  
];
export const productImg = [
  {
    index: 1,
    product: "https://i.imgur.com/hhVP2Af.png",
  },
  {
    index: 2,
    product: "https://i.imgur.com/nGD1XGf.png",
  },
  {
    index: 3,
    product: "https://i.imgur.com/PDCmjDb.png",
  },
  {
    index: 4,
    product: "https://i.imgur.com/EW2WoCE.png",
  },
  {
    index: 5,
    product: "https://i.imgur.com/nLJ5A41.png",
  },
  {
    index: 6,
    product: "https://i.imgur.com/t3Nc1HH.png",
  },
  {
    index: 7,
    product: "https://i.imgur.com/Iyzb3fw.png",
  },


  {
    index: 8,
    product: "https://i.imgur.com/efnEpc2.png",
  },
  {
    index: 9,
    product: "  https://i.imgur.com/qpJGuuH.png",
  },

];
export const blogImg = [
  {
    index: 1,
    blog: "https://i.imgur.com/HosiZpK.png",
  },
  {
    index: 2,
    blog: "https://i.imgur.com/zAYi9N6.png",
  },
  {
    index: 3,
    blog: "https://i.imgur.com/gu0oCuM.png",
  },
  {
    index: 4,
    blog: "https://i.imgur.com/zCVz5V9.png",
  },
  {
    index: 5,
    blog: "https://i.imgur.com/XjrSTHq.png",
  },
  {
    index: 6,
    blog: "https://i.imgur.com/i4uOtvD.png",
  },
  {
    index: 7,
    blog: "https://i.imgur.com/jtCHo6Y.png"
    ,
  },
];
export const footerImg = [
  {
    index: 1,
    footer: "https://i.imgur.com/gHJuRNh.png",
  },
  {
    index: 2,
    footer: "https://i.imgur.com/V5T6fvN.png",
  },
  {
    index: 3,
    footer: "https://i.imgur.com/b4WZ7LG.png",
  },
  {
    index: 4,
    footer: "https://i.imgur.com/YKPn77N.png",
  },
  {
    index: 5,
    footer: "https://i.imgur.com/yXPLWjo.png",
  },
];
