import React, { Component } from "react";
import * as Types from "../../../constants/ActionType";
import Sidebar from "../../../components/Partials/Sidebar";
import Topbar from "../../../components/Partials/Topbar";
import Footer from "../../../components/Partials/Footer";
import NotAccess from "../../../components/Partials/NotAccess";

import Alert from "../../../components/Partials/Alert";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Info from "../../../components/AgencyInfo/Detail/Info";
import Recharge from "../../../components/AgencyInfo/Detail/Recharge";

import WithDraw from "../../../components/AgencyInfo/Detail/WithDraw";
import Report from "../../../components/AgencyInfo/Detail/ReportAgency/ReportAgency";



import * as themeAction from "../../../actions/theme";
import * as helper from "../../../ultis/helpers";
class DetailPos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabId: "",
      change: "",
    };
    this.tabIndex = helper.getQueryParams("tab-index") ?? 0

  }
  componentDidMount() {
    var { store_code } = this.props.match.params;
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.state.isLoading != true &&
      typeof nextProps.permission.product_list != "undefined"
    ) {
      var permissions = nextProps.permission;

      var isShow = permissions.customer_list;
      this.setState({ isLoading: true, isShow });
    }
  }

  render() {
    var { store_code , customerId } = this.props.match.params;
    var {isShow} = this.state
    return (
      <div id="wrapper">
        <Sidebar store_code={store_code} />
        <div className="col-10 col-10-wrapper">
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Topbar store_code={store_code} />
              {typeof isShow == "undefined" ? (
                  <div style={{ height: "500px" }}></div>
                ) : isShow == true ? (
                <div className="container-fluid">
                  <Alert
                    type={Types.ALERT_UID_STATUS}
                    alert={this.props.alert}
                  />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="h4 title_content mb-0 text-gray-800">
                      Thông tin đại lý
                    </h4>
                  </div>
                  <br></br>

                  <div className="card shadow mb-4 tab-pos">
                    <div className="card-body">
                      <Tabs
                        defaultIndex={this.tabIndex}
                      >
                        <TabList>
                            <Tab>
                              <i class="fa fa-user"></i>
                              <span style={{ fontSize: "0.8rem" }}>
                                Thông tin đại lý
                              </span>
                            </Tab>
                            <Tab>
                              <i class="fa fa-credit-card"></i>
                              <span style={{ fontSize: "0.8rem" }}>
                               Lịch sử nạp tiền
                              </span>
                            </Tab>
                            <Tab>
                              <i class="fa fa-credit-card"></i>
                              <span style={{ fontSize: "0.8rem" }}>
                              Lịch sử rút tiền
                              </span>
                            </Tab>
                            <Tab>
                              <i class="fas fa-chart-bar"></i>
                              <span style={{ fontSize: "0.8rem" }}>
                                Báo cáo
                              </span>
                            </Tab>
                    
                    
                        </TabList>

                          <TabPanel>
                            <Info
                            customerId = {customerId}
                              store_code={store_code}
                            />
                          </TabPanel>
                          <TabPanel>
                         
                              <Recharge
                              store_code={store_code}
                              customerId = {customerId}

                            />
                          </TabPanel>
                          <TabPanel>
                          <WithDraw
                              store_code={store_code}
                              customerId = {customerId}

                            />
                          </TabPanel>

                          <TabPanel>
                            <Report
                            agency_by_customer_id = {customerId}
                              store_code={store_code}
                            />
                          </TabPanel>
                  
                     
                      </Tabs>
                    </div>
                  </div>
                </div>
             ) : (
              <NotAccess />
            )}
            </div>

            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducers.login.authentication,
    theme: state.themeReducers.theme,
    permission: state.authReducers.permission.data,

    alert: state.themeReducers.alert_uid,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchTheme: (store_code) => {
      dispatch(themeAction.fetchTheme(store_code));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailPos);
