import React, { Component } from "react";
import { connect } from "react-redux";
import * as agencyAction from "../../../../actions/agency";
import Table from "./Table";
import TableDraw from "./TableDraw";

class ListAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 0,
    };
  }

  componentDidMount() {
    var {customerId} = this.props

    this.props.fetchAllAgencyWithDraw(this.props.store_code , this.getParams(customerId));  
  }
  getParams = (customer_id , page=1)=>
  {
    var queryString = "?params=true"
    if(customer_id)
    queryString = queryString + `&customer_id=${customer_id}`
    if(page)
    queryString = queryString + `&page=${page}`

    return queryString
  }

  render() {
    var { recharge, store_code, with_draw } = this.props;
    var {type} = this.state

    console.log(type)
    return (
      <div id="wrapper">
 

        <div className="" style={{width : "100%"}}>
        <div>
 
        </div>
        <br></br>
        <TableDraw recharge={with_draw} store_code={store_code} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recharge: state.agencyReducers.agency.recharge,
    with_draw: state.agencyReducers.agency.with_draw,

    auth: state.authReducers.login.authentication,
  };
};
const mapDispatchToProps = (dispatch, props) => {
  return {

    fetchAllAgencyWithDraw: (store_code,params) => {
      dispatch(agencyAction.fetchAllAgencyWithDraw(store_code , params));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ListAgency);
