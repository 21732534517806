import React, { Component } from "react";
import { connect } from "react-redux";
import { shallowEqual } from "../../../ultis/shallowEqual";
import { filter_arr, format, getDetailAdress } from "../../../ultis/helpers";
import { getDDMMYYYHis } from "../../../ultis/date";

export default class ComponentTemplate1ToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (
      !shallowEqual(this.props.stores, nextProps.stores) ||
      typeof this.state.user_name == "undefined"
    ) {
      var stores = nextProps.stores;
      (stores ?? []).forEach((element, index) => {
        if (element.store_code == this.props.store_code) {
          this.setState({
            user_name: stores[index].user.name,
            store_name: stores[index].name,
            user_phone: stores[index].user.phone_number,
          });
        }
      });
    }
    if (
      !shallowEqual(this.props.bill, nextProps.bill) ||
      typeof this.state.customer_name == "undefined"
    ) {
      var bill = nextProps.bill;
      if (typeof bill.order_code != "undefined" && bill.order_code != null) {
        var address = "";
        if (
          typeof bill.customer_address != "undefined" &&
          bill.customer_address != null
        ) {
          address = {
            customer_name: bill.customer_address.name,

            customer_address: getDetailAdress(
              bill.customer_address.address_detail,
              bill.customer_address?.wards_name,
              bill.customer_address?.district_name,
              bill.customer_address?.province_name
            ),

            customer_phone: bill.customer_address.phone,
          };
        } else {
          address = {
            customer_name: bill.customer_name,
            customer_address: getDetailAdress(
              bill.customer_address_detail,
              bill.customer_address?.wards_name,
              bill.customer_address?.district_name,
              bill.customer_address?.province_name
            ),

            customer_phone: bill.customer_address?.phone,
          };
        }
        this.setState({
          ...address,
          order_code: bill.order_code,
          order_date: bill.created_at,
          total_final: bill.total_final,
        });
      }
    }
  }

  showListProduct = () => {
    var arr = [];
    var bill = this.props.bill;
    if (
      typeof bill.line_items_at_time == "undefined" ||
      bill.line_items_at_time == null
    ) {
      return null;
    }


    var quantityLast = 0;
    var totalLast = 0;
    var totalProduct = 0;

    bill.line_items_at_time.forEach((element, index) => {
      if (index >= 5) {
        totalLast = totalLast + (element.before_price || element.before_discount_price) *
          element.quantity;
        quantityLast = quantityLast + element.quantity
        totalProduct += 1;
      }
    })

    bill.line_items_at_time.forEach((element, index) => {

      if (index <= 5) {
        if (index == 5) {
          arr.push(
            <tr>
              <td>{index + 1}</td>
              <td style={{ textAlign: "start" }}>{"... " + totalProduct + " sản phẩm khác"}</td>
              <td>{quantityLast}</td>
              <td style={{ textAlign: "end" }}>
                {format(totalLast)}
              </td>
            </tr>
          );
        } else {
          arr.push(
            <tr>
              <td>{index + 1}</td>
              <td style={{ textAlign: "start" }}>{element.name} {element.is_bonus == true ? "(Thưởng)" : ""}</td>
              <td>{element.quantity}</td>
              <td style={{ textAlign: "end" }}>
                {element.is_bonus == true ? format(0) : format(
                  (element.before_price || element.before_discount_price) *
                  element.quantity
                )}
              </td>
            </tr>
          );
        }
      }

    });

    arr.push(
      <React.Fragment>
        {
          bill.total_shipping_fee > 0 && (
            <tr>
              <td></td>

              <td style={{ textAlign: "start" }}>Phí vận chuyển</td>
              <td></td>

              <td style={{ textAlign: "end" }} colSpan="3">
                + {format(bill.total_shipping_fee || 0)}
              </td>
            </tr>
          )
        }
        < tr >
          <td></td>

          <td style={{ textAlign: "start" }}>Giảm giá, Voucher, Combo</td>
          <td></td>

          <td style={{ textAlign: "end" }} colSpan="3">
            -{" "}
            {format(
              (bill.product_discount_amount || 0) +
              (bill.voucher_discount_amount || 0) +
              (bill.combo_discount_amount || 0)
            )}
          </td>
        </tr >
      </React.Fragment >
    );
    arr.push(
      <React.Fragment>
        <tr>
          <td></td>

          <td style={{ textAlign: "start" }}>Tổng</td>
          <td></td>

          <td style={{ textAlign: "end" }} colSpan="3">
            {format(
              (bill.total_final || 0)
            )}
          </td>
        </tr>
      </React.Fragment>
    );

    return arr;
  };

  showBonusAgency = () => {
    var bill = this.props.bill;
    var { bonus_agency_history } = bill;
    var { reward_value, reward_name } = bonus_agency_history;

    var arr = [];
    arr.push(
      <tr>
        <td>{reward_name}</td>
        <td style={{ textAlign: "end" }}>{format(reward_value)}</td>
      </tr>
    );

    return arr;
  };

  render() {
    var state = this.state;
    var { bill, badges, store, currentBranch } = this.props;



    var total_product =
      Array.isArray(bill.line_items_at_time) == true
        ? bill.line_items_at_time.length
        : 0;
    var store_address =
      typeof badges.address_pickup == "undefined"
        ? null
        : badges.address_pickup == null
          ? null
          : badges.address_pickup.address_detail +
          ", " +
          badges.address_pickup.wards_name +
          ", " +
          badges.address_pickup.district_name +
          ", " +
          badges.address_pickup.province_name;


    return (
      <div style={{ margin: "32px", fontSize: "32px" }}>

        <div className="row">

          <div className="col-3">
            <div style={{
              margin: 20
            }}>

              <img
                height={170}
                src={store.logo_url} alt="Logo" />

            </div>

          </div>


          <div className="col-9" >

            <div style={{
              lineHeight: "0px",

            }}>

              <p class="" id="sale_user_name">
                <span style={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: 32,
                  margin: 0,
                  padding: 0
                }}>
                  {" "}
                  {store.name ?? bill.store_name}
                </span>
              </p>
              <p class="" id="info" style={{
                margin: 0,
                padding: 0
              }}>
                <span><b>ĐC:</b> </span>
                {store.address}
              </p>

              <p class="" id="info" style={{
                margin: 0,
                padding: 0
              }}>
                <span><b>SĐT/Zalo:</b> {store.user?.phone_number ?? bill.user_phone} </span>
              </p>

            </div>


          </div>
        </div>
        <div className="row">
          <div class="col-12" style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
            <center>
              <p
                style={{
                  fontSize: "35px",
                  fontWeight: "bold",
                  textAlign: "center",

                  margin: 0,
                  padding: 0

                }}
              >
                HÓA ĐƠN BÁN HÀNG
              </p>
              <p
                style={{
                  fontSize: "42px", textAlign: "center", margin: 0,
                  padding: 0
                }}
                className="order_code">Mã: <b>{bill.order_code ?? bill.order_code}</b></p>
              <div style={{
                fontSize: "35px", textAlign: "center", margin: 0,
                padding: 0
              }}>
                Ngày: {getDDMMYYYHis(bill.created_at)}
              </div>
            </center>
          </div>
        </div>


        <div className="parent" style={{
          border: "none",
          fontSize: "35px"
        }}>

          <div className="row">
            <div class="col-12-print" style={{
              fontSize: "35px"
            }}>

              <div class="">

                <p class="" id="sale_user_name">
                  <span style={{ fontWeight: "500", fontSize: "35px" }}>
                    <b> Tên khách hàng: </b>{state?.customer_name ?? bill.customer_name ?? bill?.customer?.name ?? ""}
                  </span>
                </p>
                <p class="" id="info" style={{ fontSize: "35px" }}>
                  <span><b>Địa chỉ: </b></span> {getDetailAdress(
                    bill.customer_address?.address_detail,
                    bill.customer_address?.wards_name,
                    bill.customer_address?.district_name,
                    bill.customer_address?.province_name
                  )}
                </p>
                <p class="" id="info" style={{ fontSize: "35px" }}>
                  <span><b>Số điện thoại:  </b></span> {state?.customer_phone ?? bill.customer_phone ?? bill?.customer?.phone_number ?? ""}
                </p>

              </div>


              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Tên sản phẩm</th>
                    <th>Số lượng</th>
                    <th>Thành tiền</th>
                  </tr>
                </thead>
                <tbody style={{
                  fontSize: 23
                }}>{this.showListProduct()}</tbody>
              </table>

              <p class="" id="info" style={{
                marginTop: 10,
                fontSize: 25
              }}>
                <span>Quý khách vui lòng quay video khi mở hàng để shop hỗ trợ nhanh nhất  </span>
              </p>

            </div>
          </div>

          {/* {bill.bonus_agency_history != null && (
            <div className="row">
              <div class="col-12-print">
                <p className="order_code">Thưởng cho đại lý</p>

                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Tên phần thưởng</th>
                      <th>Trị giá</th>
                    </tr>
                  </thead>
                  <tbody>{this.showBonusAgency()}</tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className="row"

          >
            <div class="col-6-not-border" style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Người mua hàng
              </p>

              <div>


                <div
                  style={{
                    fontSize: "32px",
                    position: "absolute",
                    bottom: "5px",
                  }}
                >

                </div>
              </div>
            </div>
            <div class="col-6-not-border" style={{ position: "relative" }}>
              <p
                style={{
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Người bán hàng
              </p>

              <div>


                <div
                  style={{
                    fontSize: "32px",
                    position: "absolute",
                    bottom: "5px",
                  }}
                >

                </div>
              </div>
            </div>
          </div> */}


        </div>

      </div>
    );
  }
}
