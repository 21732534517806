import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import * as agencyAction from "../../../../actions/agency";
import { format, formatNoD } from "../../../../ultis/helpers";
import ModalUpdate from "./WithDraw/ModalUpdate";
import ModalDetail from "./WithDraw/ModalDetail";

import moment from "moment";
import Pagination from "./WithDraw/Pagination";
import Item from "./WithDraw/Item";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        name: "",
        id: "",
      },
      modalUpdate: {
        name: "",
        id: "",
      },
      idModalDetail: null
    };

  }

  handleRemove = (id, name) => {
    this.setState({ modal: { name: name, id: id } });
  };



  handleModalUpdateRecharge = (data) => {
    this.setState({
      modalUpdate: data,
    });
    window.$("#updateWithDraw").modal("show")
  }

  handleShowDetail = (id) => {
    this.setState({ idModalDetail: id });
    window.$("#modalDetail").modal("show")
  }

  showData = (recharge) => {
    var { store_code } = this.props;
    var is_show_cus = this.props.customerId == null
    var result = null;
    if (recharge.length > 0) {
      result = recharge.map((v, index) => {
        return (
          <Item v={v}
          is_show_cus={v}
            handleShowDetail={this.handleShowDetail}
            handleModalUpdateRecharge={this.handleModalUpdateRecharge}
          ></Item>
        );
      });
    } else {
      return result;
    }
    return result;
  };



  render() {
    var recharge =
      typeof this.props.recharge == "undefined" ? [] : this.props.recharge;

    var { modal, modalUpdate, idModalDetail } = this.state;
    var { store_code, customerId } = this.props;
    var is_show_cus = this.props.customerId == null
    return (
      <div class="table-responsive">
        <ModalUpdate customerId={customerId} modal={modalUpdate} store_code={this.props.store_code} />
        <ModalDetail recharge={recharge.data} idModalDetail={idModalDetail} store_code={this.props.store_code} />

        <table class="table table-border table-hover table-striped">
          <thead>
            <tr>
              <th className="order-id">Mã giao dịch</th>
              <th className="date">Thời gian</th>

              {is_show_cus && <th className="name">Khách hàng</th>}
              {is_show_cus && <th className="phone_number">Số điện thoại</th>}

              <th className="status">Số tiền rút</th>

              <th style={{ textAlign: "center" }}>Trạng thái</th>
              {/* <th>Hành động</th> */}
            </tr>
          </thead>

          <tbody>{this.showData(recharge.data || [])}</tbody>
        </table>

        <Pagination customerId={this.props.customerId} limit={20} store_code={store_code} data={recharge} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateAgency: (store_code, id, data) => {
      dispatch(agencyAction.updateAgency(store_code, id, data));
    },
  };
};
export default connect(null, mapDispatchToProps)(Table);
